header {
  text-align: center;
  padding: 1rem;
  border-bottom: 1px solid #e6e6e6
}

.logo_header_au {
  height: 20px;
  width: 55px;
  vertical-align: middle;
  margin-right: 20px;
}

.logo_header_uq {
  height: 25px;
  width: 125px;
  vertical-align: middle;
  margin-left: 20px;
}
.logo_header_povo {
  width: 125px;
  vertical-align: middle;
  margin-left: 20px;
}

footer {
  text-align: center;
  padding: 4rem 0 8.8rem;
  border-top: .2rem solid #d1d1d1;
  background-color: #F2F2F2;
}

footer img {
  height: 38px;
  width: 84px;
}

footer a {
  color: #555;
  font-size: 68.745;
}

.text_emphasis {
  color: red;
}

.question_main_radio span {
  padding: 0;
  margin: 0 .2rem;
}

.question_main_radio label {
  align-items: start;
  margin: .2rem auto;
}

.question_sub_radio span {
  padding: 0;
  margin-right: .2rem;
}

.question_sub_radio label {
  align-items: start;
  margin: .3rem auto;
}